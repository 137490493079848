var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CsOperatorSettingsView d-flex flex-column flex-fill",
      attrs: { "data-test-id": "general_settings-view" },
    },
    [
      _c("PageView", { staticClass: "py-4 emobg-background-color-white" }, [
        _c("h2", [_vm._v(" General settings ")]),
      ]),
      _vm.tabs.length
        ? _c("MuiTabs", {
            staticClass: "MuiTabs--scrollable px-5",
            attrs: { tabs: _vm.tabs, "data-test-id": "tabs" },
            on: {
              "active-tab": (category) => (_vm.categorySelected = category),
            },
          })
        : _vm._e(),
      _c(
        "PageView",
        {
          staticClass: "d-flex flex-column flex-fill",
          attrs: { "is-tab-content": "" },
        },
        [
          _c(
            "MuiCard",
            { staticClass: "position-relative" },
            [
              _vm.isLoading
                ? _c(
                    "div",
                    { staticStyle: { "min-height": "300px" } },
                    [
                      _c("ui-loader", {
                        attrs: {
                          label: _vm.settingsLoaderText,
                          absolute: "",
                          "data-test-id": "loader",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.settings && !_vm.isLoading
                ? _c(
                    "MuiValidationWrapper",
                    {
                      on: {
                        areAllValid: (isValid) => (_vm.isFormValid = isValid),
                      },
                    },
                    [
                      _vm._l(_vm.settingsFiltered, function (group) {
                        return _c(
                          "div",
                          { key: group.name, staticClass: "mb-3" },
                          [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v(
                                " " + _vm._s(_vm.sentenceCase(group.name)) + " "
                              ),
                            ]),
                            _vm._l(
                              group.subGroups,
                              function (subgroup, indexSubgroup) {
                                return _c("div", { key: indexSubgroup }, [
                                  subgroup.name
                                    ? _c("h5", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sentenceCase(subgroup.name)
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "row" },
                                    _vm._l(subgroup.inputs, function (input) {
                                      return _c(
                                        "div",
                                        {
                                          key: input.key.key,
                                          staticClass: "col-md-6 mb-3",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "d-flex align-items-center emobg-label-1 mb-2",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      input.key.alias ||
                                                        input.key.key
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      input.key.units
                                                        ? `(${input.key.units})`
                                                        : ""
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              input.key.contextualHelpLangKey
                                                ? _c(
                                                    "ui-tooltip",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center ml-1",
                                                      attrs: {
                                                        tooltip:
                                                          input.key
                                                            .contextualHelpLangKey,
                                                        "no-arrow": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-items-center",
                                                        },
                                                        [
                                                          _c("ui-icon", {
                                                            attrs: {
                                                              icon: _vm.ICONS
                                                                .info,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm.getComponent(input.key.inputType)
                                            ? _c(
                                                _vm.getComponent(
                                                  input.key.inputType
                                                ),
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value:
                                                            _vm.getComponentValidations(
                                                              input.key
                                                            ),
                                                          expression:
                                                            "getComponentValidations(input.key)",
                                                        },
                                                      ],
                                                      tag: "Component",
                                                    },
                                                    "Component",
                                                    _vm.getComponentProps(
                                                      input.key
                                                    ),
                                                    false
                                                  ),
                                                  _vm.getComponentListeners(
                                                    input.key
                                                  )
                                                )
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                ])
                              }
                            ),
                          ],
                          2
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c(
                            "ui-button",
                            {
                              attrs: {
                                disabled: _vm.isSaveButtonDisabled,
                                loading: _vm.formStatus.LOADING,
                                "data-test-id": "save-button",
                              },
                              on: { clickbutton: _vm.saveSettingsRequest },
                            },
                            [_vm._v(" Save ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }