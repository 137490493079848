var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.PageView,
    {
      staticClass: "ImportOperatorsView d-flex flex-column flex-fill",
      attrs: { "data-test-id": "import_operator-view" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Create operator ")]),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-fill flex-column emobg-border-1 emobg-border-color-ground-light emobg-background-color-white",
        },
        [
          _c(
            "h2",
            {
              staticClass:
                "p-4 emobg-font-large emobg-background-color-ground-lightest",
            },
            [_vm._v(" Import ")]
          ),
          _c(
            "div",
            { staticClass: "p-4" },
            [
              _c(
                "label",
                { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                [_vm._v(" Operator file (xlsx)* ")]
              ),
              _c(_setup.DragFileComponent, {
                ref: "dragFileComponent",
                attrs: {
                  "draggable-height": 400,
                  "accepted-formats":
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "data-test-id": "file-input",
                },
                scopedSlots: _vm._u([
                  {
                    key: "extraInformation",
                    fn: function () {
                      return [
                        _c(
                          "span",
                          { staticClass: "d-block emobg-font-x-small mt-2" },
                          [_vm._v(" Only XLSX format allowed (.xlsx) ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _setup.operatorFile,
                  callback: function ($$v) {
                    _setup.operatorFile = $$v
                  },
                  expression: "operatorFile",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-grow-1 justify-content-end" },
            [
              _c(
                "ui-button",
                {
                  attrs: {
                    disabled: !_setup.hasRequiredFieldsFilled,
                    loading: _setup.getValue(
                      _setup.importOperatorStatus,
                      "LOADING",
                      false
                    ),
                  },
                  on: { clickbutton: _setup.uploadFile },
                },
                [_vm._v(" Upload ")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }