var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "LegalDocumentsListView",
      attrs: { "data-test-id": "legal_documents_list-view" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [
            _vm._v(" Legal documents "),
          ]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalOpened = true
                },
              },
            },
            [_vm._v(" Create legal document ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: _vm.LIST_REF,
            attrs: {
              facets: _vm.facets,
              filters: `${_vm.getOperatorFilter({
                attribute: "cs_operator_id",
              })} OR cs_operator_id:null`,
              index: _vm.LEGAL_DOCUMENTS_INDEX,
              "table-config": _vm.mainConfig,
              "item-actions": _vm.itemActions,
              "data-test-id": "list",
            },
          }),
        ],
        1
      ),
      _vm.isModalOpened
        ? _c("LegalDocumentsForm", {
            attrs: {
              "legal-document-uuid": _vm.legalDocumentUuid,
              "legal-document-to-copy-uuid": _vm.legalDocumentUuidToCopy,
              "on-success": _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isPublishModalOpen
        ? _c("LegalDocumentPublishModal", {
            attrs: {
              "legal-document": _vm.legalDocument,
              "on-success": _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalOpen
        ? _c("LegalDocumentDeleteModal", {
            attrs: {
              "legal-document": _vm.legalDocument,
              "on-success": _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }