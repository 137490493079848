var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "LegalDocumentPublish",
        attrs: { header: null },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: {
                    title: `Publish ${_vm.legalDocument.legalDocumentType.name}?`,
                  },
                }),
                _c(
                  "p",
                  { staticClass: "emobg-body-1 emobg-color-ink-light pt-3" },
                  [
                    _vm._v(
                      " If you publish this legal document, it will replace the actual active version and at the same time it will freeze the content of this legal document so that it cannot be edited anymore. "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      loading: _vm.publishDocumentStatus.LOADING,
                      "data-test-id": "publish-button",
                    },
                    on: { clickbutton: _vm.publishDocument },
                  },
                  [_vm._v(" Publish ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }