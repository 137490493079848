export const EXCLUDED_INPUT_KEYS = [
  'allow_automatic_validation',
];

export const EXCLUDED_CATEGORIES = [
  'auto_intervention',
];

export const FIELD_TYPES = {
  checkbox: 'checkbox',
  select: 'select',
  text: 'text',
  textarea: 'textarea',
};

export const VALUE_TYPES = {
  boolean: 'boolean',
  integer: 'integer',
  double: 'double',
  string: 'string',
};
