var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "AgentFormComponent",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.small,
          buttons: _vm.modalButtons,
          title: _vm.titleModal,
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.agents,
                    scope: _vm.AGENT_SCOPES.form,
                    action: _vm.isEditing ? "Update agent" : "Create agent",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    attrs: { id: "AgentForm" },
                    on: {
                      areAllValid: (valid) => (_vm.isFormValid = valid),
                      submit: _vm.onSubmit,
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              title: (user) =>
                                `${user.first_name} ${user.last_name} - ${user.email}`,
                              index: _vm.ALGOLIA_INDEXES.users,
                              filters: _vm.isEditing ? "" : `is_agent: false`,
                              label: "User*",
                              placeholder: "Select an user",
                              "path-value": "uuid",
                              name: "user",
                            },
                            model: {
                              value: _vm.inputs.userUuid,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "userUuid", $$v)
                              },
                              expression: "inputs.userUuid",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              title: (operator) => operator.name,
                              index: _vm.ALGOLIA_INDEXES.csOperators,
                              multiple: "",
                              label: "Cs Operators*",
                              placeholder: "Select one or more operators",
                              "path-value": "uuid",
                              name: "operators",
                            },
                            model: {
                              value: _vm.inputs.operatorUuids,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "operatorUuids", $$v)
                              },
                              expression: "inputs.operatorUuids",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("MuiAlgoliaSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              title: (role) => _vm.sentenceCase(role.name),
                              index: _vm.ALGOLIA_INDEXES.roles,
                              multiple: "",
                              filters: "group:backoffice",
                              label: "Roles*",
                              placeholder: "Select one or more roles",
                              "path-value": "name",
                              name: "roles",
                            },
                            model: {
                              value: _vm.inputs.roles,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "roles", $$v)
                              },
                              expression: "inputs.roles",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }