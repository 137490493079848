<script>
import get from 'lodash/get';
import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';
import { mapActions, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { camelCaseKeys } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { LEGAL_DOCUMENT_STATUS } from '@domains/CRM/CsOperators/LegalDocuments/legalDocuments.const';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  MarkdownComponent,
  StoreNotificationComponent,
  TranslatableFieldComponent,
} from '@/components';
import { LEGAL_DOCUMENTS_SCOPES } from '../../store/LegalDocumentsModule';

export default {
  name: 'LegalDocumentsForm',
  components: {
    CancelButton,
    ContentCellComponent,
    GenericModalComponent,
    MarkdownComponent,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiValidationWrapper,
    StoreNotificationComponent,
    TranslatableFieldComponent,
  },
  directives: {
    Validate,
  },
  props: {
    legalDocumentUuid: {
      type: String,
      default: undefined,
    },
    legalDocumentToCopyUuid: {
      type: String,
      default: undefined,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        legalDocumentTypeId: null,
        version: null,
        contentTranslations: {},
      },
      language: 'en_GB',
      legalDocumentTypeSelected: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.legalDocuments, {
      legalDocument: state => state[LEGAL_DOCUMENTS_SCOPES.legalDocument].data,
      legalDocumentStatus: state => state[LEGAL_DOCUMENTS_SCOPES.legalDocument].STATUS,
      legalDocumentFormStatus: state => state[LEGAL_DOCUMENTS_SCOPES.form].STATUS,
    }),
    isEditing() {
      return !!this.legalDocumentUuid;
    },
    canFieldsBeEditByStatus() {
      return !this.isEditing || (this.isEditing && get(this.legalDocument, 'status') === LEGAL_DOCUMENT_STATUS.draft);
    },
    modalTitle() {
      const editPreviewTitle = this.canFieldsBeEditByStatus ? 'Edit legal document' : 'Preview legal document';
      return this.isEditing
        ? editPreviewTitle
        : 'Create legal document';
    },
    visibilityOfDocumentTypeText() {
      if (!has(this.legalDocumentTypeSelected, 'restrictedVisibility')) {
        return this.FALLBACK_MESSAGE.dash;
      }

      return this.legalDocumentTypeSelected.restrictedVisibility
        ? 'Applies only to your CS Operator'
        : 'Applies to all CS Operators';
    },
    hasSameValues() {
      return this.isEditing && isEqual(this.inputs, this.originalInputs);
    },
    dataForRequest() {
      const data = omit(cloneDeep(this.inputs), 'contentTranslations');
      return {
        ...data,
        ...this.inputs.contentTranslations,
      };
    },
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.LEGAL_DOCUMENTS_SCOPES = LEGAL_DOCUMENTS_SCOPES;

    if (this.isEditing || this.legalDocumentToCopyUuid) {
      const documentUuid = this.isEditing ? this.legalDocumentUuid : this.legalDocumentToCopyUuid;
      await this.getLegalDocument(documentUuid);

      this.inputs.legalDocumentTypeId = get(this, 'legalDocument.legalDocumentType.id');
      this.inputs.version = get(this, 'legalDocument.version');
      this.inputs.contentTranslations = mapKeys(cloneDeep(this.legalDocument.contentTranslations), (_value, key) => `content${upperFirst(key)}`);
      this.legalDocumentTypeSelected = get(this, 'legalDocument.legalDocumentType');
      this.originalInputs = cloneDeep(this.inputs);
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.legalDocuments, [
      'getLegalDocument',
      'postLegalDocument',
      'putLegalDocument',
    ]),
    get,
    map,
    camelCaseKeys,
    upperFirst,
    async submit() {
      const textAction = this.isEditing ? 'edited' : 'added';
      const action = this.isEditing ? this.putLegalDocument : this.postLegalDocument;
      const requestData = this.isEditing ? { legalDocumentId: this.legalDocument.id, data: this.dataForRequest } : this.dataForRequest;

      await action(requestData);

      if (!this.legalDocumentFormStatus.ERROR) {
        this.$notify({
          message: `Legal document successfully <span class="emobg-font-weight-semibold">${textAction}</span>`,
          textAction: '',
        });
        this.onSuccess();
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :title="legalDocumentStatus.LOADING ? '' : modalTitle"
    class="LegalDocumentsForm v1-MuiModal--adaptative"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.legalDocuments"
        :scope="LEGAL_DOCUMENTS_SCOPES.form"
        :action="isEditing ? 'edit legal document' : 'add legal document'"
      />
    </template>
    <template #body>
      <ui-loader
        v-if="legalDocumentStatus.LOADING"
        relative
        label="Loading..."
        data-test-id="loader"
      />
      <MuiValidationWrapper
        v-else
        @areAllValid="valid => isFormValid = valid"
      >
        <div class="row">
          <div class="col-md-6 mb-4">
            <template v-if="canFieldsBeEditByStatus">
              <MuiAlgoliaSelect
                v-model="inputs.legalDocumentTypeId"
                :title="legalDocumentType => legalDocumentType.name || legalDocumentType.internal_name"
                :index="ALGOLIA_INDEXES.legalDocumentTypes"
                label="Document type*"
                placeholder="Select a legal document type"
                class="w-100"
                path-value="id"
                name="legalDocumentTypeId"
                data-test-id="document_type-select"
                @selected="legalDocumentType => legalDocumentTypeSelected = camelCaseKeys(legalDocumentType)"
              />
            </template>

            <ContentCellComponent
              v-else
              :value="get(legalDocument, 'legalDocumentType.name') || get(legalDocument, 'legalDocumentType.internalName')"
              data-test-id="document_type-text"
              label="Document type"
            />
          </div>
          <div class="col-md-6 mb-4">
            <ContentCellComponent
              :value="visibilityOfDocumentTypeText"
              label="Visibility"
              data-test-id="visibility-text"
            />
          </div>
          <div class="col-md-6 mb-4">
            <MuiInputText
              v-if="canFieldsBeEditByStatus"
              v-model="inputs.version"
              v-validate="{
                isRequired: true,
              }"
              label="Version*"
              name="version"
              data-test-id="version-input"
            />
            <ContentCellComponent
              v-else
              :value="inputs.version"
              label="Version"
              data-test-id="version-text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <TranslatableFieldComponent
              v-model="inputs.contentTranslations"
              :language="language"
              :disabled="!canFieldsBeEditByStatus"
              prefix="content"
              rows="30"
              data-test-id="content-input"
              @update:language="selectedLanguage => language = selectedLanguage"
            />
            <div
              v-if="canFieldsBeEditByStatus"
              class="emobg-background-color-ground-lightest emobg-color-ink-light mt-1 p-2 border-radius"
            >
              # Title<br>
              ## Subtitle<br>
              *italic*<br>
              **bold**<br>
              * bullet point<br>
            </div>
          </div>
          <div
            class="col-md-6 mt-6 pt-3"
            style="max-height: 600px; overflow: auto;"
          >
            <MarkdownComponent
              :content="inputs.contentTranslations"
              :language="`content${upperFirst(language)}`"
              class="my-4"
              data-test-id="content-text"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="$emit('closeModal')"
        />

        <ui-button
          v-if="canFieldsBeEditByStatus || !isEditing"
          :disabled="!(isFormValid && inputs.legalDocumentTypeId) || hasSameValues"
          :loading="legalDocumentFormStatus.LOADING"
          class="wmin-initial ml-2"
          data-test-id="save-button"
          @clickbutton="submit"
        >
          {{ isEditing ? 'Save' : 'Create' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
