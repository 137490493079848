<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    legalDocument: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.legalDocuments, {
      publishDocumentStatus: state => state.publishDocument.STATUS,
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.legalDocuments, [
      'putPublishLegalDocument',
    ]),
    get,
    async publishDocument() {
      const legalDocumentTypeId = get(this, 'legalDocument.legalDocumentTypeId');
      const version = get(this, 'legalDocument.version');
      await this.putPublishLegalDocument({ legalDocumentId: this.legalDocument.id, data: { legalDocumentTypeId, version } });
      if (!this.publishDocumentStatus.ERROR) {
        this.$notify({
          message: 'Legal document successfully <span class="emobg-font-weight-semibold">published</span>',
          textAction: '',
        });

        this.onSuccess();
      }
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    class="LegalDocumentPublish"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent :title="`Publish ${legalDocument.legalDocumentType.name}?`" />
      <p class="emobg-body-1 emobg-color-ink-light pt-3">
        If you publish this legal document, it will replace the actual active version and at the same time it will
        freeze the content of this legal document so that it cannot be edited anymore.
      </p>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <ui-button
        :loading="publishDocumentStatus.LOADING"
        data-test-id="publish-button"
        @clickbutton="publishDocument"
      >
        Publish
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
