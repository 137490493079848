<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import { HTTP_STATUS_CODES, navigationErrorHandler } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOT_FOUND as NOT_FOUND_URL } from '@/constants/urls';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { scopes } from './store/PayoutsModule';
import PayoutAccountFormComponent from './components/PayoutAccountFormComponent';
import PayoutFormComponent from './components/PayoutFormComponent';
import { contentCells } from './config/AlgoliaPayoutsTableConfig';

export default {
  name: 'PayoutsView',

  components: {
    MuiAlgoliaList,
    PayoutAccountFormComponent,
    PayoutFormComponent,
  },

  data() {
    return {
      clientUuid: null,
      isBankAccountModalOpen: false,
      isPayoutFormModalOpen: false,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.crm.payouts, {
      isLoading: state => get(state, 'paymentMethod.STATUS.LOADING'),
      paymentMethod: state => get(state, 'paymentMethod.data'),
    }),
  },

  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;

    this.clientUuid = this.$route.params.userUuid;
    this.getPaymentInfo();
  },

  beforeDestroy() {
    this.clearData({ scopes: [scopes.paymentMethod] });
  },

  methods: {
    isEmpty,

    ...mapActions(DOMAINS_MODEL.crm.payouts, ['getUserPayoutPaymentMethod']),
    ...mapMutations(DOMAINS_MODEL.crm.payouts, ['clearData']),

    getPaymentInfo() {
      try {
        this.getUserPayoutPaymentMethod(this.clientUuid);
      } catch (error) {
        if (error.status !== HTTP_STATUS_CODES.notFound) {
          this.$router.push(NOT_FOUND_URL).catch(navigationErrorHandler);
        }
      }
    },
  },
};
</script>

<template>
  <div class="PayoutsView">
    <ui-card
      :header="$t('CRM.Payouts.view.title')"
      :description="$t('CRM.Payouts.view.description')"
    >
      <ui-loader
        v-if="isLoading"
        data-test-id="loader"
      />

      <div class="d-flex">
        <h2 class="flex-fill">
          {{ $t('CRM.Payouts.view.subtitle') }}
        </h2>

        <ui-button
          :face="FACES.outline"
          data-test-id="payouts_button_openBankAccountModal"
          @clickbutton="isBankAccountModalOpen = true"
        >
          {{ isEmpty(paymentMethod) ? $t('CRM.Payouts.view.addAccount') : $t('CRM.Payouts.view.changeAccount') }}
        </ui-button>
      </div>

      <section
        v-show="!isEmpty(paymentMethod)"
        class="PayoutsView__Details"
        data-test-id="payouts_section_paymentMethodSection"
      >
        <div class="p-3">
          <p class="emobg-title-3 mb-2">
            {{ $t('CRM.Payouts.view.holderName') }}
          </p>
          <p data-test-id="payouts_p_accountHolderName">
            {{ paymentMethod.holder }}
          </p>
        </div>

        <div class="p-3 mb-2">
          <p class="emobg-title-3 mb-2">
            {{ $t('CRM.Payouts.view.lastDigits') }}
          </p>
          <p data-test-id="payouts_p_iban">
            {{ paymentMethod.number }}
          </p>
        </div>
      </section>

      <section
        v-show="!isEmpty(paymentMethod)"
        class="PayoutsView__List position-relative"
      >
        <hr class="MuiCard__separator separator">
        <div class="d-flex mb-4">
          <h1 class="flex-fill">
            {{ $t('CRM.Payouts.view.issued') }}
          </h1>

          <ui-button
            :face="FACES.outline"
            data-test-id="payouts_button_openPayoutForm"
            @clickbutton="isPayoutFormModalOpen = true"
          >
            {{ $t('CRM.Payouts.view.create') }}
          </ui-button>
        </div>

        <MuiAlgoliaList
          :is-search-enabled="false"
          :table-config="contentCells"
          :filters="`user.uuid:${clientUuid}`"
          :index="ALGOLIA_INDEXES.payoutTransactions"
        />
      </section>
    </ui-card>

    <PayoutAccountFormComponent
      v-model="isBankAccountModalOpen"
      :uuid="clientUuid"
    />

    <PayoutFormComponent
      v-model="isPayoutFormModalOpen"
      :uuid="clientUuid"
    />
  </div>
</template>

<style lang="scss">
  .PayoutsView {
    .MuiAlgoliaTable {
      width: unset !important;
    }
  }
</style>
