var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteAgent",
        attrs: { header: null, size: _vm.SIZES.small },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("DeleteModalBodyComponent", {
            attrs: {
              text: _vm.text,
              title:
                "Are you sure you want to remove full backoffice access to this user?",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            on: {
              click: function ($event) {
                return _vm.$emit("closeModal")
              },
            },
          }),
          _c(
            "ui-button",
            {
              staticClass: "DeleteButton wmin-initial",
              attrs: {
                color: _vm.COLORS.danger,
                loading: _vm.deleteAgentStatus.LOADING,
              },
              on: { clickbutton: _vm.removeAgent },
            },
            [_vm._v(" Remove access ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }