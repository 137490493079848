import get from 'lodash/get';
import { FALLBACK_MESSAGE, formatUtc, sentenceCase } from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import MediaTemplateComponent from '../components/MediaTemplateComponent';

export const contentCells = [
  {
    attributeName: 'date_ts',
    title: 'Date',
    displayPriority: 1,
    minWidth: 80,
    transformValue: formatUtc,
  },
  {
    attributeName: 'type',
    title: 'Type',
    displayPriority: 1,
  },
  {
    title: 'Booking ID',
    displayPriority: 1,
    type: RECORD_TYPES.link,
    transformResult: ({ booking }) => get(booking, 'id'),
    url: ({ booking }) => `/carsharing/bookings/${get(booking, 'uuid')}`,
  },
  {
    title: 'Case ID',
    displayPriority: 1,
    type: RECORD_TYPES.link,
    transformResult: result => {
      const caseId = get(result, 'case.id');
      return caseId ? `${caseId}` : '';
    },
    url: result => {
      const caseUuid = get(result, 'case.uuid');
      return caseUuid ? `/cases-service/case-events/${caseUuid}` : FALLBACK_MESSAGE.dash;
    },
  },
  {
    attributeName: 'amount_formatted',
    title: 'Amount',
    displayPriority: 2,
  },
  {
    attributeName: 'status',
    title: 'Status',
    displayPriority: 1,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'submitted_by',
    title: 'Submitted by',
    displayPriority: 2,
    transformValue: value => `${value.first_name} ${value.last_name}`,
  },
  {
    attributeName: 'reason',
    title: 'Reason',
    displayPriority: 2,
  },
  {
    attributeName: 'iban',
    title: 'IBAN ends in',
    displayPriority: 2,
  },
  {
    title: 'Attachment',
    displayPriority: 2,
    type: RECORD_TYPES.component,
    component: MediaTemplateComponent,
    props: result => ({
      result,
      payout: result,
    }),
  }];
