var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PayoutsView" },
    [
      _c(
        "ui-card",
        {
          attrs: {
            header: _vm.$t("CRM.Payouts.view.title"),
            description: _vm.$t("CRM.Payouts.view.description"),
          },
        },
        [
          _vm.isLoading
            ? _c("ui-loader", { attrs: { "data-test-id": "loader" } })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h2", { staticClass: "flex-fill" }, [
                _vm._v(" " + _vm._s(_vm.$t("CRM.Payouts.view.subtitle")) + " "),
              ]),
              _c(
                "ui-button",
                {
                  attrs: {
                    face: _vm.FACES.outline,
                    "data-test-id": "payouts_button_openBankAccountModal",
                  },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isBankAccountModalOpen = true
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isEmpty(_vm.paymentMethod)
                          ? _vm.$t("CRM.Payouts.view.addAccount")
                          : _vm.$t("CRM.Payouts.view.changeAccount")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "section",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmpty(_vm.paymentMethod),
                  expression: "!isEmpty(paymentMethod)",
                },
              ],
              staticClass: "PayoutsView__Details",
              attrs: { "data-test-id": "payouts_section_paymentMethodSection" },
            },
            [
              _c("div", { staticClass: "p-3" }, [
                _c("p", { staticClass: "emobg-title-3 mb-2" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("CRM.Payouts.view.holderName")) + " "
                  ),
                ]),
                _c(
                  "p",
                  { attrs: { "data-test-id": "payouts_p_accountHolderName" } },
                  [_vm._v(" " + _vm._s(_vm.paymentMethod.holder) + " ")]
                ),
              ]),
              _c("div", { staticClass: "p-3 mb-2" }, [
                _c("p", { staticClass: "emobg-title-3 mb-2" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("CRM.Payouts.view.lastDigits")) + " "
                  ),
                ]),
                _c("p", { attrs: { "data-test-id": "payouts_p_iban" } }, [
                  _vm._v(" " + _vm._s(_vm.paymentMethod.number) + " "),
                ]),
              ]),
            ]
          ),
          _c(
            "section",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmpty(_vm.paymentMethod),
                  expression: "!isEmpty(paymentMethod)",
                },
              ],
              staticClass: "PayoutsView__List position-relative",
            },
            [
              _c("hr", { staticClass: "MuiCard__separator separator" }),
              _c(
                "div",
                { staticClass: "d-flex mb-4" },
                [
                  _c("h1", { staticClass: "flex-fill" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("CRM.Payouts.view.issued")) + " "
                    ),
                  ]),
                  _c(
                    "ui-button",
                    {
                      attrs: {
                        face: _vm.FACES.outline,
                        "data-test-id": "payouts_button_openPayoutForm",
                      },
                      on: {
                        clickbutton: function ($event) {
                          _vm.isPayoutFormModalOpen = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("CRM.Payouts.view.create")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("MuiAlgoliaList", {
                attrs: {
                  "is-search-enabled": false,
                  "table-config": _vm.contentCells,
                  filters: `user.uuid:${_vm.clientUuid}`,
                  index: _vm.ALGOLIA_INDEXES.payoutTransactions,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("PayoutAccountFormComponent", {
        attrs: { uuid: _vm.clientUuid },
        model: {
          value: _vm.isBankAccountModalOpen,
          callback: function ($$v) {
            _vm.isBankAccountModalOpen = $$v
          },
          expression: "isBankAccountModalOpen",
        },
      }),
      _c("PayoutFormComponent", {
        attrs: { uuid: _vm.clientUuid },
        model: {
          value: _vm.isPayoutFormModalOpen,
          callback: function ($$v) {
            _vm.isPayoutFormModalOpen = $$v
          },
          expression: "isPayoutFormModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }