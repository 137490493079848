<script setup>
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { computed, ref } from 'vue';
import { useStoreModule } from '@/store/composable/useStoreModule';
import { DragFileComponent, PageView } from '@/components';
import { base64WithoutName, getValue } from '@emobg/web-utils';

const operatorFile = ref(null);
const hasRequiredFieldsFilled = computed(() => !!getValue(operatorFile, 'value.length', null));

const {
  postUploadOperatorFile,
  importOperatorStatus,
  createdOperator,
} = useStoreModule(DOMAINS_MODEL.crm.operatorSettings, {
  actions: [
    'postUploadOperatorFile',
  ],
  state: {
    importOperatorStatus: state => getValue(state, 'import.STATUS', null),
    createdOperator: state => getValue(state, 'import.data', null),
  },
});

const { add: notify } = useStoreModule(DOMAINS_MODEL.app.messages.notifications.index, {
  mutations: [
    'add',
  ],
});

const uploadFile = async () => {
  await postUploadOperatorFile(base64WithoutName(operatorFile.value[0]));

  if (!getValue(importOperatorStatus, 'value.ERROR', false)) {
    const operatorName = getValue(createdOperator, 'value.name', null);
    notify({ message: `Operator ${operatorName} successfully <span class="emobg-font-weight-semibold">created</span>` });
  }
};
</script>
<template>
  <PageView
    class="ImportOperatorsView d-flex flex-column flex-fill"
    data-test-id="import_operator-view"
  >
    <h1 class="mb-2">
      Create operator
    </h1>

    <div class="d-flex flex-fill flex-column emobg-border-1 emobg-border-color-ground-light emobg-background-color-white">
      <h2 class="p-4 emobg-font-large emobg-background-color-ground-lightest">
        Import
      </h2>

      <div class="p-4">
        <label class="d-block emobg-font-weight-semibold mb-1"> Operator file (xlsx)* </label>
        <DragFileComponent
          ref="dragFileComponent"
          v-model="operatorFile"
          :draggable-height="400"
          accepted-formats="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          data-test-id="file-input"
        >
          <template #extraInformation>
            <span class="d-block emobg-font-x-small mt-2">
              Only XLSX format allowed (.xlsx)
            </span>
          </template>
        </DragFileComponent>
      </div>
    </div>
    <div class="BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky">
      <div class="d-flex flex-grow-1 justify-content-end">
        <ui-button
          :disabled="!hasRequiredFieldsFilled"
          :loading="getValue(importOperatorStatus, 'LOADING', false)"
          @clickbutton="uploadFile"
        >
          Upload
        </ui-button>
      </div>
    </div>
  </PageView>
</template>
<style lang="scss">
.ImportOperatorsView {
  .BottomActions {
    bottom: 0;
    align-items: center;
    justify-content: end;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.32);
  }
}
</style>
