<script>
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import first from 'lodash/first';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import map from 'lodash/map';
import range from 'lodash/range';
import split from 'lodash/split';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  MuiCard,
  MuiTabs,
} from '@emobg/vue-base';
import {
  MuiInputText,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { camelCaseKeys, sentenceCase, toBoolean } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ToggleComponent from '@/components/ToggleComponent/ToggleComponent';

import { PATTERN_INPUT_VALIDATIONS, toDecimalFormat, toNumberUtil } from '@/utils';
import { OPERATOR_SETTINGS_SCOPES } from './store/CsOperatorSettingsModule';
import {
  EXCLUDED_CATEGORIES,
  EXCLUDED_INPUT_KEYS,
  FIELD_TYPES,
  VALUE_TYPES,
} from './const/operatorSettings.const';

export default {
  name: 'CsOperatorSettingsView',
  directives: {
    Validate,
  },
  components: {
    MuiCard,
    MuiSelect,
    MuiTabs,
    MuiTextarea,
    MuiValidationWrapper,
    PageView,
  },
  data() {
    return {
      isFormValid: true,
      isLoading: true,
      categorySelected: null,
      inputs: {},
      originalInputs: {},
      tabs: [],
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['activeOperator']),
    ...mapState(DOMAINS_MODEL.crm.operatorSettings, {
      categories: state => camelCaseKeys(state[OPERATOR_SETTINGS_SCOPES.categories].data),
      settings: state => camelCaseKeys(state[OPERATOR_SETTINGS_SCOPES.settings].data),
      formStatus: state => state[OPERATOR_SETTINGS_SCOPES.form].STATUS,
    }),
    settingsFiltered() {
      return map(this.settings, group => ({
        ...group,
        subGroups: map(group.subGroups, subgroup => ({
          ...subgroup,
          inputs: filter(subgroup.inputs, input => !includes(EXCLUDED_INPUT_KEYS, input.key.key)),
        })),
      }));
    },
    isSaveButtonDisabled() {
      return isEqual(this.inputs, this.originalInputs) || !this.isFormValid || this.formStatus.LOADING;
    },
    settingsLoaderText() {
      return this.categorySelected
        ? `Loading settings for ${sentenceCase(this.categorySelected)}`
        : 'Loading operator settings categories';
    },
  },
  watch: {
    async categorySelected() {
      await this.fetchSettings();
    },
  },
  async created() {
    await this.getOperatorConfigCategories();
    const filteredCategories = filter(this.categories, category => !includes(EXCLUDED_CATEGORIES, category));
    this.categorySelected = first(filteredCategories);
    this.tabs = map(filteredCategories, category => ({
      label: sentenceCase(category),
      url: category,
    }));
  },
  methods: {
    sentenceCase,
    ...mapActions(DOMAINS_MODEL.crm.operatorSettings, [
      'getOperatorConfigCategories',
      'getOperatorConfigSettingsByCategory',
      'putOperatorConfigValues',
    ]),
    async fetchSettings() {
      this.isLoading = true;
      await this.getOperatorConfigSettingsByCategory({
        operatorId: this.activeOperator.id,
        category: this.categorySelected,
      });
      this.createDataInputs();
    },
    createDataInputs() {
      this.inputs = {};

      each(this.settingsFiltered, group => {
        each(group.subGroups, subGroup => {
          each(subGroup.inputs, input => {
            const {
              key: inputInfo,
              value,
            } = input;
            const realValue = value || inputInfo.defaultValue;
            const valueTransformer = {
              [VALUE_TYPES.boolean]: settingValue => toBoolean(settingValue),
              [VALUE_TYPES.integer]: settingValue => toNumberUtil(settingValue, false),
              [VALUE_TYPES.double]: settingValue => toDecimalFormat(settingValue, {
                dotFormat: true,
              }),
              default: settingValue => settingValue,
            };
            this.$set(this.inputs, inputInfo.key, {
              id: inputInfo.id,
              value: (valueTransformer[inputInfo.valueType] || valueTransformer.default)(realValue),
            });
          });
        });
      });

      this.originalInputs = cloneDeep(this.inputs);

      this.isLoading = false;
    },
    getComponent(type) {
      const mapper = {
        [FIELD_TYPES.checkbox]: ToggleComponent,
        [FIELD_TYPES.select]: MuiSelect,
        [FIELD_TYPES.text]: MuiInputText,
        [FIELD_TYPES.textarea]: MuiTextarea,
      };
      return mapper[type];
    },
    getComponentProps(input) {
      const {
        allowedValues,
        inputType,
        key,
        valueType,
      } = input;
      const mapperTestId = {
        [FIELD_TYPES.checkbox]: 'checkbox',
        [FIELD_TYPES.text]: 'input',
        [FIELD_TYPES.select]: 'select',
        [FIELD_TYPES.textarea]: 'textarea',
      };

      let props = {
        class: 'w-100',
        modelValue: this.inputs[key].value,
        name: key,
        'data-test-id': `${key}-${mapperTestId[inputType]}`,
      };

      if (inputType === FIELD_TYPES.checkbox) {
        props = {
          ...props,
          class: '',
        };
      }

      if (inputType === FIELD_TYPES.select) {
        props = {
          ...props,
          options: this.getAllowedValues(allowedValues, valueType),
        };
      }

      if (inputType === FIELD_TYPES.textarea) {
        props = {
          ...props,
          rows: 4,
          class: 'no-resize',
        };
      }

      return props;
    },
    getComponentListeners(input) {
      const {
        inputType,
        key,
        valueType,
      } = input;

      /* eslint-disable no-return-assign */
      const defaultListener = { change: newValue => this.inputs[key].value = newValue };

      if (inputType === FIELD_TYPES.text || inputType === FIELD_TYPES.textarea) {
        const inputTextMapper = {
          [VALUE_TYPES.string]: { input: newValue => this.inputs[key].value = newValue },
          [VALUE_TYPES.integer]: { input: newValue => this.inputs[key].value = toNumberUtil(newValue, false) },
          [VALUE_TYPES.double]: {
            input: newValue => this.inputs[key].value = newValue,
            blur: newValue => this.inputs[key].value = toDecimalFormat(newValue, { dotFormat: true }),
          },
        };
        /* eslint-enable no-return-assign */

        return inputTextMapper[valueType] || defaultListener;
      }

      return defaultListener;
    },
    getComponentValidations(input) {
      const { inputType, valueType } = input;
      const mapper = {
        [FIELD_TYPES.select]: {
          isRequired: true,
        },
        [FIELD_TYPES.text]: {
          isRequired: true,
        },
      };

      if (inputType === FIELD_TYPES.text) {
        if (valueType === VALUE_TYPES.integer) {
          mapper[FIELD_TYPES.text].isPattern = PATTERN_INPUT_VALIDATIONS.wholeNumber;
        } else if (valueType === VALUE_TYPES.double) {
          mapper[FIELD_TYPES.text].isPattern = PATTERN_INPUT_VALIDATIONS.decimalNumber;
        }
      }

      return mapper[inputType];
    },
    getAllowedValues(allowedValues, valueType) {
      if (!allowedValues) {
        return [];
      }

      if (indexOf(allowedValues, '|') > -1) {
        const numbers = split(allowedValues, '|');
        const firstNumber = first(numbers);
        const lastNumber = last(numbers);
        return range(firstNumber, (parseInt(lastNumber, 10) + 1));
      }

      if (indexOf(allowedValues, ',') > -1) {
        const splittedValues = split(allowedValues, ',');
        return valueType === VALUE_TYPES.string
          ? splittedValues
          : map(splittedValues, Number);
      }

      return [allowedValues];
    },
    async saveSettingsRequest() {
      const dataToSend = map(this.inputs);
      await this.putOperatorConfigValues(dataToSend);

      if (!this.formStatus.ERROR) {
        this.$notify({
          message: 'Settings successfully <span class="emobg-font-weight-semibold">updated</span>',
          textAction: '',
        });
        this.originalInputs = cloneDeep(this.inputs);
      }
    },
  },
};
</script>

<template>
  <div
    class="CsOperatorSettingsView d-flex flex-column flex-fill"
    data-test-id="general_settings-view"
  >
    <PageView class="py-4 emobg-background-color-white">
      <h2>
        General settings
      </h2>
    </PageView>
    <MuiTabs
      v-if="tabs.length"
      :tabs="tabs"
      class="MuiTabs--scrollable px-5"
      data-test-id="tabs"
      @active-tab="category => categorySelected = category"
    />
    <PageView
      is-tab-content
      class="d-flex flex-column flex-fill"
    >
      <MuiCard class="position-relative">
        <div
          v-if="isLoading"
          style="min-height: 300px;"
        >
          <ui-loader
            :label="settingsLoaderText"
            absolute
            data-test-id="loader"
          />
        </div>
        <MuiValidationWrapper
          v-if="settings && !isLoading"
          @areAllValid="isValid => isFormValid = isValid"
        >
          <div
            v-for="group in settingsFiltered"
            :key="group.name"
            class="mb-3"
          >
            <h4 class="mb-4">
              {{ sentenceCase(group.name) }}
            </h4>
            <div
              v-for="(subgroup, indexSubgroup) in group.subGroups"
              :key="indexSubgroup"
            >
              <h5 v-if="subgroup.name">
                {{ sentenceCase(subgroup.name) }}
              </h5>
              <div class="row">
                <div
                  v-for="input in subgroup.inputs"
                  :key="input.key.key"
                  class="col-md-6 mb-3"
                >
                  <label class="d-flex align-items-center emobg-label-1 mb-2">
                    <span>
                      {{ input.key.alias || input.key.key }} {{ input.key.units ? `(${input.key.units})` : '' }}
                    </span>
                    <ui-tooltip
                      v-if="input.key.contextualHelpLangKey"
                      :tooltip="input.key.contextualHelpLangKey"
                      no-arrow
                      class="d-flex align-items-center ml-1"
                    >
                      <div class="d-flex align-items-center">
                        <ui-icon :icon="ICONS.info" />
                      </div>
                    </ui-tooltip>
                  </label>
                  <Component
                    :is="getComponent(input.key.inputType)"
                    v-if="getComponent(input.key.inputType)"
                    v-validate="getComponentValidations(input.key)"
                    v-bind="getComponentProps(input.key)"
                    v-on="getComponentListeners(input.key)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <ui-button
              :disabled="isSaveButtonDisabled"
              :loading="formStatus.LOADING"
              data-test-id="save-button"
              @clickbutton="saveSettingsRequest"
            >
              Save
            </ui-button>
          </div>
        </MuiValidationWrapper>
      </MuiCard>
    </PageView>
  </div>
</template>

<style lang="scss">
  .CsOperatorSettingsView {
    .no-resize {
      textarea {
        resize: none;
      }
    }

    textarea[rows="4"] {
      height: 92px;
    }
  }
</style>
