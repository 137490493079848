import join from 'lodash/join';
import map from 'lodash/map';

export const exportColumns = [
  {
    attributeName: 'id',
    title: 'ID',
  },
  {
    attributeName: 'user_name',
    title: 'User',
    transformResult: agent => `${agent.user.first_name} ${agent.user.last_name} - ${agent.user.email}`,
  },
  {
    attributeName: 'user.roles',
    title: 'Backoffice roles',
    transformResult: agent => join(map(agent.user.roles, 'display_name'), ', '),
  },
  {
    attributeName: 'cs_operators',
    title: 'Access to operator',
    transformResult: agent => join(map(agent.cs_operators, 'name'), ', '),
  },
];
