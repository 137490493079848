<script>
import defaultsDeep from 'lodash/defaultsDeep';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import pick from 'lodash/pick';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { Cancel } from '@/constants/modalButtons.const';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { AGENT_SCOPES } from '../store/AgentsModule';

export default {
  name: 'AgentFormComponent',
  components: {
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    agent: {
      type: Object,
      default: undefined,
    },
    successCallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        userUuid: null,
        operatorUuids: [],
        roles: [],
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.agents, {
      isFormError: state => state[AGENT_SCOPES.form].STATUS.ERROR,
      isFormLoading: state => state[AGENT_SCOPES.form].STATUS.LOADING,
    }),
    titleModal() {
      return this.agent ? 'Update backoffice agent' : 'Create new backoffice agent';
    },
    modalButtons() {
      return [
        defaultsDeep(
          {
            listeners: {
              click: () => this.$emit('closeModal'),
            },
            attributes: {
              'data-test-id': 'agent_form_modal-cancel-button',
            },
          },
          Cancel,
        ),
        {
          attributes: {
            form: 'AgentForm',
            type: 'submit',
            loading: this.isFormLoading,
            disabled: !this.isFormValid,
            'data-test-id': 'agent_form_modal-confirm-button',
          },
          text: 'Confirm',
        },
      ];
    },
    isEditing() {
      return !isNil(this.agent);
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.AGENT_SCOPES = AGENT_SCOPES;
    this.sentenceCase = sentenceCase;

    this.clearErrors({ scope: AGENT_SCOPES.form });

    if (this.agent) {
      this.inputs.userUuid = this.agent.user.uuid;
      this.inputs.operatorUuids = map(this.agent.csOperators, 'uuid');
      this.inputs.roles = map(this.agent.user.roles, 'name');
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.agents, [
      'postAgentFromUser',
      'putAgentOperators',
      'putAgentRoles',
    ]),
    ...mapMutations(DOMAINS_MODEL.crm.agents, [
      'resetData',
      'clearErrors',
    ]),
    async onSubmit() {
      this.clearErrors({ scope: AGENT_SCOPES.form });
      const message = this.isEditing ? 'Agent updated successfully!' : 'Agent added successfully!';
      if (this.isEditing) {
        await this.putAgentOperators({ agentUuid: this.agent.uuid, data: { operator_uuids: this.inputs.operatorUuids } });
        await this.putAgentRoles({ agentUuid: this.agent.uuid, data: pick(this.inputs, ['roles']) });
      } else {
        await this.postAgentFromUser(this.inputs);
      }

      if (!this.isFormError) {
        this.$notify({
          message,
          textAction: 'OK',
        });
        this.$emit('closeModal');
        this.resetData({ scope: AGENT_SCOPES.form });
        this.successCallback();
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.small"
    :buttons="modalButtons"
    :title="titleModal"
    class="AgentFormComponent"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.agents"
        :scope="AGENT_SCOPES.form"
        :action="isEditing ? 'Update agent' : 'Create agent'"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        id="AgentForm"
        @areAllValid="valid => isFormValid = valid"
        @submit="onSubmit"
      >
        <div class="d-flex flex-column">
          <div class="mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.userUuid"
              v-validate="{
                isRequired: true,
              }"
              :title="user => `${user.first_name} ${user.last_name} - ${user.email}`"
              :index="ALGOLIA_INDEXES.users"
              :filters="isEditing ? '' : `is_agent: false`"
              label="User*"
              placeholder="Select an user"
              class="w-100"
              path-value="uuid"
              name="user"
            />
          </div>
          <div class="mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.operatorUuids"
              v-validate="{
                isRequired: true,
              }"
              :title="operator => operator.name"
              :index="ALGOLIA_INDEXES.csOperators"
              multiple
              label="Cs Operators*"
              placeholder="Select one or more operators"
              class="w-100"
              path-value="uuid"
              name="operators"
            />
          </div>
          <div>
            <MuiAlgoliaSelect
              v-model="inputs.roles"
              v-validate="{
                isRequired: true,
              }"
              :title="role => sentenceCase(role.name)"
              :index="ALGOLIA_INDEXES.roles"
              multiple
              filters="group:backoffice"
              label="Roles*"
              placeholder="Select one or more roles"
              class="w-100"
              path-value="name"
              name="roles"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
