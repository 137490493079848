var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "AgentsListView",
      attrs: { "data-test-id": "agents_list-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mb-2",
        },
        [
          _c("h1", [_vm._v(" Agents ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "vehicle_cases_tab-create-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Add agent ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "agents",
            attrs: {
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.exportColumns,
              facets: _vm.facets,
              labels: {
                searchTable: "Search",
              },
              "table-config": _vm.contentCells,
              index: _vm.ALGOLIA_INDEXES.agents,
              "data-test-id": "list",
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isDeleteModalVisible
        ? _c("DeleteAgentComponent", {
            attrs: {
              agent: _vm.agent,
              callback: _vm.onFormSuccess,
              "data-test-id": "delete_agent",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isModalVisible
        ? _c("AgentFormComponent", {
            attrs: {
              agent: _vm.agent,
              "success-callback": _vm.onFormSuccess,
              "data-test-id": "agent-form",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }