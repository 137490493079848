var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ui-toggle", {
    staticClass: "ToggleComponent",
    attrs: {
      value: _vm.modelValue,
      text: _vm.label || "",
      disabled: _vm.disabled,
    },
    on: { changevalue: ({ detail }) => _vm.$emit("change", detail) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }