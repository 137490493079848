<script>
export default {
  name: 'AgentsView',
};
</script>

<template>
  <div class="AgentsView d-flex flex-column flex-fill">
    <RouterView />
  </div>
</template>

