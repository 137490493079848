var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MediaTemplateComponent" },
    [
      _vm.isLoadingPayouts ? _c("ui-loader", { staticClass: "p-1" }) : _vm._e(),
      _c(
        "div",
        {
          staticClass: "emobg-link-primary emobg-body-2",
          on: {
            click: function ($event) {
              $event.preventDefault()
              _vm.showMediaAttachment(_vm.head(_vm.payout.media))
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.getFallbackMessage) + " ")]
      ),
      _c(
        "MuiModal",
        _vm._b(
          {
            on: {
              "close-modal": function ($event) {
                _vm.isOpen = false
              },
            },
            model: {
              value: _vm.isOpen,
              callback: function ($$v) {
                _vm.isOpen = $$v
              },
              expression: "isOpen",
            },
          },
          "MuiModal",
          _vm.modalConfig,
          false
        ),
        [
          _c(
            "div",
            { staticClass: "py-2", attrs: { slot: "body" }, slot: "body" },
            [
              _c("img", {
                staticClass: "mw-100",
                attrs: { src: _vm.payoutData.url, alt: "payout" },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }