<script>
import { camelCaseKeys, DELAY } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import {
  contentCells,
  exportColumns,
  facets,
} from './config/index';
import AgentFormComponent from '../components/AgentFormComponent';
import DeleteAgentComponent from '../components/DeleteAgentComponent';

export default {
  name: 'AgentsListView',
  components: {
    AgentFormComponent,
    DeleteAgentComponent,
    MuiAlgoliaList,
    PageView,
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      isDeleteModalVisible: false,
      agent: null,
    };
  },
  created() {
    const element = 'agent';
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells();
    this.exportColumns = exportColumns;
    this.facets = facets;
    this.itemsActions = [
      {
        label: this.$t('Common.Actions.edit_element', { element }),
        method: agent => {
          this.agent = camelCaseKeys(agent);
          this.isModalVisible = true;
        },
      },
      {
        label: `Remove ${element} access`,
        type: 'danger',
        class: 'emobg-color-danger',
        method: agent => {
          this.agent = camelCaseKeys(agent);
          this.isDeleteModalVisible = true;
        },
      },
    ];
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
      this.isDeleteModalVisible = false;
      this.agent = null;
    },
    onFormSuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.agents, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <PageView
    class="AgentsListView"
    data-test-id="agents_list-view"
  >
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h1>
        Agents
      </h1>

      <ui-button
        data-test-id="vehicle_cases_tab-create-button"
        @clickbutton="isModalVisible = true"
      >
        Add agent
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="agents"
        :item-actions="itemsActions"
        :export-columns="exportColumns"
        :facets="facets"
        :labels="{
          searchTable: 'Search',
        }"
        :table-config="contentCells"
        :index="ALGOLIA_INDEXES.agents"
        data-test-id="list"
        is-export-enabled
      />
    </div>
    <DeleteAgentComponent
      v-if="isDeleteModalVisible"
      :agent="agent"
      :callback="onFormSuccess"
      data-test-id="delete_agent"
      @closeModal="closeModal"
    />
    <AgentFormComponent
      v-if="isModalVisible"
      :agent="agent"
      :success-callback="onFormSuccess"
      data-test-id="agent-form"
      @closeModal="closeModal"
    />
  </PageView>
</template>
