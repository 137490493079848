<script>
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiModal,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { BUTTON_TYPES } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import IBAN from 'iban';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { CancelButton, SaveButton } from '@/components';

export default {
  name: 'PayoutAccountFormComponent',
  components: {
    CancelButton,
    MuiAlgoliaSelect,
    MuiModal,
    MuiInputText,
    MuiValidationWrapper,
    SaveButton,
  },
  directives: {
    Validate,
  },
  props: {
    uuid: {
      type: String,
      default: undefined,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
    bankAccount: {
      type: Object,
      default: undefined,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      payoutPaymentMethod: {
        holder: '',
        country: null,
        number: '',
      },
      isIbanCorrect: undefined,
      isFormValid: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.payouts, {
      payoutStatus: state => state.paymentMethod.STATUS,
      payoutData: state => state.paymentMethod.data,
      payoutError: state => state.paymentMethod.error,
    }),
    hasCountry() {
      return !isNil(this.payoutPaymentMethod.country);
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.modalConfig = {
      title: this.$t('CRM.Payouts.account.title'),
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.payouts, [
      'postUserPayoutPaymentMethod',
      'postCompanyPayoutPaymentMethod',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    validateAccountNumber() {
      const isValid = IBAN.isValid(this.removeSpaces(this.payoutPaymentMethod.number));
      this.payoutPaymentMethod.number = IBAN.printFormat(this.payoutPaymentMethod.number);
      return { message: this.$t('CRM.Payouts.account.invalidIban'), isValid };
    },
    async savePayoutAccountForm() {
      const payoutPaymentMethod = { ...this.payoutPaymentMethod };
      payoutPaymentMethod.number = this.removeSpaces(this.payoutPaymentMethod.number);
      const payload = { uuid: this.uuid, payoutPaymentMethod };
      if (this.isCompany) {
        await this.postCompanyPayoutPaymentMethod(payload);
      } else {
        await this.postUserPayoutPaymentMethod(payload);
      }

      if (this.payoutStatus.ERROR) {
        const message = get(this.payoutError, 'message');
        this.$throwError({ message });
      } else {
        this.notify({ message: this.$t('CRM.Payouts.account.successMessage') });
        this.closeModal();
      }
    },
    removeSpaces(string) {
      return string.replace(/ /g, '');
    },
    closeModal() {
      this.payoutPaymentMethod = {
        holder: '',
        country: null,
        number: '',
      };
      this.$emit('input', false);
    },
  },
};
</script>

<template>
  <MuiModal
    v-bind="modalConfig"
    :is-open="value"
    @modal-closed="closeModal"
  >
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="py-3">
          <MuiInputText
            v-model="payoutPaymentMethod.holder"
            v-validate="{
              isRequired: true,
            }"
            :label="$t('CRM.Payouts.account.holderNameLabel')"
            name="holder"
            class="w-100 mb-2"
          />
          <MuiAlgoliaSelect
            v-model="payoutPaymentMethod.country"
            v-validate="{
              isRequired: true,
            }"
            :title="country => country.name"
            :label="$t('CRM.Payouts.account.countryLabel')"
            :placeholder="$t('CRM.Payouts.account.countryPlaceholder')"
            :index="ALGOLIA_INDEXES.countries"
            class="w-100 mb-2"
            path-value="code"
            name="country"
          />
          <MuiInputText
            v-if="hasCountry"
            v-model="payoutPaymentMethod.number"
            v-validate="{
              validateAccountNumber,
              isRequired: true,
            }"
            :label="$t('CRM.Payouts.account.ibanLabel')"
            class="w-100 mb-2"
            name="number"
          />
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end p-3">
        <CancelButton @click="closeModal" />
        <SaveButton
          :loading="payoutStatus.LOADING"
          :disabled="!isFormValid"
          class="mr-2"
          @click="savePayoutAccountForm"
        />
      </div>
    </template>
  </MuiModal>
</template>
