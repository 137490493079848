import map from 'lodash/map';
import { RECORD_TYPES } from '@emobg/motion-ui';
import CRM_ROUTES from '@domains/CRM/router/CRMRouterMap';
import { EmailTemplate, ListTemplate } from '@/templates';
import PermissionLink from '@/components/PermissionLink/PermissionLink';
import { CRM_PERMISSIONS } from '../../../const/permissions';

export function contentCells() {
  return [
    {
      attributeName: 'user_id',
      title: 'ID',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: CRM_ROUTES.users.detail.index,
          params: { userUuid: result.user.uuid },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: `#${result.user_id}`,
      }),
      minWidth: 100,
    },
    {
      title: 'User',
      displayPriority: 1,
      minWidth: 300,
      type: RECORD_TYPES.component,
      component: EmailTemplate,
      props: result => ({
        text: `${result.user.first_name} ${result.user.last_name} - ${result.user.email}`,
        email: result.user.email,
      }),
    },
    {
      attributeName: 'user.roles',
      type: RECORD_TYPES.component,
      component: ListTemplate,
      props: result => ({
        title: 'Backoffice roles',
        items: map(result.user.roles, 'display_name'),
      }),
      isCollapseHidden: true,
      title: null,
    },
    {
      attributeName: 'cs_operators',
      type: RECORD_TYPES.component,
      component: ListTemplate,
      props: result => ({
        title: 'Access to operator',
        items: map(result.cs_operators, 'name'),
      }),
      isCollapseHidden: true,
      title: null,
    },
  ];
}
