<script>
import head from 'lodash/head';
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import { MuiModal } from '@emobg/motion-ui/v1';
import { FALLBACK_MESSAGE, isImage } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';

export default {
  name: 'MediaTemplateComponent',
  components: {
    MuiModal,
  },
  props: {
    payout: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      FALLBACK_MESSAGE,
      isLoadingPayouts: false,
      isOpen: false,
      modalConfig: {
        title: '',
        backdropDismiss: true,
        header: {
          class: 'pl-3',
          isClosable: true,
        },
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.payouts, {
      payoutStatus: state => state.media.STATUS,
      payoutData: state => state.media.data,
      payoutError: state => state.media.error,
    }),
    getFallbackMessage() {
      return get(this.payout, 'media[0].name', FALLBACK_MESSAGE.dash);
    },
  },
  methods: {
    head,
    ...mapActions(DOMAINS_MODEL.crm.payouts, [
      'getMedia',
    ]),
    async showMediaAttachment(media) {
      this.isLoadingPayouts = true;

      await this.getMedia(media.uuid);
      this.isLoadingPayouts = false;

      if (this.payoutStatus.LOADED) {
        const isMediaImage = await isImage(this.payoutData.url);
        if (isMediaImage) {
          this.modalConfig.title = media.label;
          this.isOpen = true;
        } else {
          window.open(this.payoutData.url, '_blank');
        }
      } else {
        if (this.payoutError.message) {
          this.$notify({
            message: this.payoutError.message,
            textAction: 'OK',
            type: NOTIFICATION_TYPES.error,
          });
        }
        if (this.payoutError.exception_message) {
          this.$notify({
            message: this.payoutError.exception_message,
            textAction: 'OK',
            type: NOTIFICATION_TYPES.error,
          });
        }
      }
    },
  },
};
</script>

<template>
  <div class="MediaTemplateComponent">
    <ui-loader
      v-if="isLoadingPayouts"
      class="p-1"
    />
    <div
      class="emobg-link-primary emobg-body-2"
      @click.prevent="showMediaAttachment(head(payout.media))"
    >
      {{ getFallbackMessage }}
    </div>
    <MuiModal
      v-model="isOpen"
      v-bind="modalConfig"
      @close-modal="isOpen = false"
    >
      <div
        slot="body"
        class="py-2"
      >
        <img
          :src="payoutData.url"
          class="mw-100"
          alt="payout"
        >
      </div>
    </MuiModal>
  </div>
</template>
