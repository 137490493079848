<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    agent: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    text() {
      return `${get(this, 'agent.user.firstName')} ${get(this, 'agent.user.lastName')} - ${get(this, 'agent.user.email')}`;
    },
    ...mapState(DOMAINS_MODEL.crm.agents, {
      deleteAgentStatus: state => state.deleteAgent.STATUS,
    }),
  },
  methods: {
    async removeAgent() {
      await this.deleteAgent(this.agent.uuid);
      this.$emit('closeModal');
      if (!this.deleteAgentStatus.ERROR) {
        this.$notify({
          message: 'Agent deleted successfully!',
          textAction: 'OK',
        });
        this.callback();
      }
    },
    ...mapActions(DOMAINS_MODEL.crm.agents, [
      'deleteAgent',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteAgent"
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        :text="text"
        title="Are you sure you want to remove full backoffice access to this user?"
      />
    </template>
    <template slot="footer">
      <CancelButton @click="$emit('closeModal')" />
      <ui-button
        :color="COLORS.danger"
        :loading="deleteAgentStatus.LOADING"
        class="DeleteButton wmin-initial"
        @clickbutton="removeAgent"
      >
        Remove access
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
