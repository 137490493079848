<script>
export default {
  name: 'CsOperatorsView',
};
</script>

<template>
  <div class="CsOperatorsView d-flex flex-column flex-fill">
    <RouterView />
  </div>
</template>
