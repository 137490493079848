export { contentCells } from './contentCells';
export { exportColumns } from './exportColumns';

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Roles',
      attributeName: 'user.roles.display_name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Operators',
      attributeName: 'cs_operators.name',
    },
  },
];
