import get from 'lodash/get';
import join from 'lodash/join';
import keys from 'lodash/keys';
import {
  DATE_FORMAT,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import CRM_ROUTES from '@domains/CRM/router/CRMRouterMap';
import PermissionLink from '@/components/PermissionLink/PermissionLink';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const badgeColor = {
  draft: COLORS.warning,
  published: COLORS.success,
  deprecated: GRAYSCALE.ground,
};

export function contentCells({ operatorTimezone }) {
  return [
    {
      title: 'Name',
      attributeName: 'legal_document_type.name',
      displayPriority: 1,
    },
    {
      title: 'Version',
      attributeName: 'version',
      displayPriority: 1,
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: badgeColor[status],
      }),
    },
    {
      attributeName: 'updated_at',
      title: 'Updated at',
      displayPriority: 1,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      title: 'User',
      displayPriority: 0,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: CRM_ROUTES.users.detail.index,
          params: { userUuid: result.user.uuid },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: `${get(result, 'user.first_name', '')} ${get(result, 'user.last_name', '')}`,
      }),
    },
    {
      title: 'Languages',
      attributeName: 'content_translations',
      displayPriority: 0,
      transformValue: value => join(keys(value), ', '),
    },
  ];
}

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Document type',
      attributeName: 'legal_document_type.name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
];
