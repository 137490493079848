<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    legalDocument: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.legalDocuments, {
      deleteDocumentStatus: state => state.deleteLegalDocument.STATUS,
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.legalDocuments, [
      'deleteLegalDocument',
    ]),
    get,
    async removeDocument() {
      await this.deleteLegalDocument(this.legalDocument.id);
      if (!this.deleteDocumentStatus.ERROR) {
        this.$notify({
          message: 'Legal document successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });

        this.onSuccess();
      }
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="LegalDocumentDelete"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        :title="`Delete ${legalDocument.legalDocumentType.name} draft?`"
        text="This cannot be undone"
      />
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <ui-button
        :color="COLORS.danger"
        :loading="deleteDocumentStatus.LOADING"
        data-test-id="delete-button"
        @clickbutton="removeDocument"
      >
        Delete
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
