var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "LegalDocumentsForm v1-MuiModal--adaptative",
        attrs: {
          header: { isClosable: true },
          title: _vm.legalDocumentStatus.LOADING ? "" : _vm.modalTitle,
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.legalDocuments,
                    scope: _vm.LEGAL_DOCUMENTS_SCOPES.form,
                    action: _vm.isEditing
                      ? "edit legal document"
                      : "add legal document",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.legalDocumentStatus.LOADING
                  ? _c("ui-loader", {
                      attrs: {
                        relative: "",
                        label: "Loading...",
                        "data-test-id": "loader",
                      },
                    })
                  : _c(
                      "MuiValidationWrapper",
                      {
                        on: {
                          areAllValid: (valid) => (_vm.isFormValid = valid),
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-4" },
                            [
                              _vm.canFieldsBeEditByStatus
                                ? [
                                    _c("MuiAlgoliaSelect", {
                                      staticClass: "w-100",
                                      attrs: {
                                        title: (legalDocumentType) =>
                                          legalDocumentType.name ||
                                          legalDocumentType.internal_name,
                                        index:
                                          _vm.ALGOLIA_INDEXES
                                            .legalDocumentTypes,
                                        label: "Document type*",
                                        placeholder:
                                          "Select a legal document type",
                                        "path-value": "id",
                                        name: "legalDocumentTypeId",
                                        "data-test-id": "document_type-select",
                                      },
                                      on: {
                                        selected: (legalDocumentType) =>
                                          (_vm.legalDocumentTypeSelected =
                                            _vm.camelCaseKeys(
                                              legalDocumentType
                                            )),
                                      },
                                      model: {
                                        value: _vm.inputs.legalDocumentTypeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inputs,
                                            "legalDocumentTypeId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inputs.legalDocumentTypeId",
                                      },
                                    }),
                                  ]
                                : _c("ContentCellComponent", {
                                    attrs: {
                                      value:
                                        _vm.get(
                                          _vm.legalDocument,
                                          "legalDocumentType.name"
                                        ) ||
                                        _vm.get(
                                          _vm.legalDocument,
                                          "legalDocumentType.internalName"
                                        ),
                                      "data-test-id": "document_type-text",
                                      label: "Document type",
                                    },
                                  }),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-4" },
                            [
                              _c("ContentCellComponent", {
                                attrs: {
                                  value: _vm.visibilityOfDocumentTypeText,
                                  label: "Visibility",
                                  "data-test-id": "visibility-text",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-4" },
                            [
                              _vm.canFieldsBeEditByStatus
                                ? _c("MuiInputText", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n            }",
                                      },
                                    ],
                                    attrs: {
                                      label: "Version*",
                                      name: "version",
                                      "data-test-id": "version-input",
                                    },
                                    model: {
                                      value: _vm.inputs.version,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputs, "version", $$v)
                                      },
                                      expression: "inputs.version",
                                    },
                                  })
                                : _c("ContentCellComponent", {
                                    attrs: {
                                      value: _vm.inputs.version,
                                      label: "Version",
                                      "data-test-id": "version-text",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("TranslatableFieldComponent", {
                                attrs: {
                                  language: _vm.language,
                                  disabled: !_vm.canFieldsBeEditByStatus,
                                  prefix: "content",
                                  rows: "30",
                                  "data-test-id": "content-input",
                                },
                                on: {
                                  "update:language": (selectedLanguage) =>
                                    (_vm.language = selectedLanguage),
                                },
                                model: {
                                  value: _vm.inputs.contentTranslations,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inputs,
                                      "contentTranslations",
                                      $$v
                                    )
                                  },
                                  expression: "inputs.contentTranslations",
                                },
                              }),
                              _vm.canFieldsBeEditByStatus
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "emobg-background-color-ground-lightest emobg-color-ink-light mt-1 p-2 border-radius",
                                    },
                                    [
                                      _vm._v(" # Title"),
                                      _c("br"),
                                      _vm._v(" ## Subtitle"),
                                      _c("br"),
                                      _vm._v(" *italic*"),
                                      _c("br"),
                                      _vm._v(" **bold**"),
                                      _c("br"),
                                      _vm._v(" * bullet point"),
                                      _c("br"),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col-md-6 mt-6 pt-3",
                              staticStyle: {
                                "max-height": "600px",
                                overflow: "auto",
                              },
                            },
                            [
                              _c("MarkdownComponent", {
                                staticClass: "my-4",
                                attrs: {
                                  content: _vm.inputs.contentTranslations,
                                  language: `content${_vm.upperFirst(
                                    _vm.language
                                  )}`,
                                  "data-test-id": "content-text",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    _vm.canFieldsBeEditByStatus || !_vm.isEditing
                      ? _c(
                          "ui-button",
                          {
                            staticClass: "wmin-initial ml-2",
                            attrs: {
                              disabled:
                                !(
                                  _vm.isFormValid &&
                                  _vm.inputs.legalDocumentTypeId
                                ) || _vm.hasSameValues,
                              loading: _vm.legalDocumentFormStatus.LOADING,
                              "data-test-id": "save-button",
                            },
                            on: { clickbutton: _vm.submit },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.isEditing ? "Save" : "Create") +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }