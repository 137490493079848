<script>
import { mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { camelCaseKeys, DELAY } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import { LEGAL_DOCUMENT_STATUS } from '../legalDocuments.const';
import { contentCells, facets } from './config';
import LegalDocumentsForm from './components/LegalDocumentsForm';
import LegalDocumentPublishModal from './components/LegalDocumentPublishModal';
import LegalDocumentDeleteModal from './components/LegalDocumentDeleteModal';

export default {
  name: 'LegalDocumentsListView',
  components: {
    LegalDocumentDeleteModal,
    LegalDocumentsForm,
    LegalDocumentPublishModal,
    MuiAlgoliaList,
    PageView,
  },
  data() {
    return {
      isModalOpened: false,
      isPublishModalOpen: false,
      isDeleteModalOpen: false,
      legalDocument: null,
      legalDocumentUuid: null,
      legalDocumentUuidToCopy: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    mainConfig() {
      return contentCells({ operatorTimezone: this.operatorTimezone });
    },
  },
  created() {
    this.LEGAL_DOCUMENTS_INDEX = ALGOLIA_INDEXES.legalDocs;
    this.LIST_REF = 'legalDocs';
    this.facets = facets;
    this.itemActions = [
      {
        label: legalDocument => (legalDocument.status === LEGAL_DOCUMENT_STATUS.draft ? 'Edit legal document' : 'Preview legal document'),
        method: legalDocument => {
          this.legalDocumentUuid = legalDocument.uuid;
          this.isModalOpened = true;
        },
        class: 'emobg-font-weight-semibold',
      },
      {
        label: 'Duplicate legal document',
        method: legalDocument => {
          this.legalDocumentUuidToCopy = legalDocument.uuid;
          this.isModalOpened = true;
        },
        class: 'emobg-font-weight-semibold',
      },
      {
        label: 'Publish legal document',
        method: legalDocument => {
          this.legalDocument = camelCaseKeys(legalDocument);
          this.isPublishModalOpen = true;
        },
        isVisible: legalDocument => legalDocument.status === LEGAL_DOCUMENT_STATUS.draft,
        class: 'emobg-font-weight-semibold',
      },
      {
        label: 'Delete legal document',
        method: legalDocument => {
          this.legalDocument = camelCaseKeys(legalDocument);
          this.isDeleteModalOpen = true;
        },
        isVisible: legalDocument => legalDocument.status === LEGAL_DOCUMENT_STATUS.draft,
        class: 'emobg-color-danger emobg-font-weight-semibold',
      },
    ];
  },
  methods: {
    closeModal() {
      this.isModalOpened = false;
      this.isPublishModalOpen = false;
      this.isDeleteModalOpen = false;
      this.legalDocument = null;
      this.legalDocumentUuidToCopy = null;
      this.legalDocumentUuid = null;
    },
    onFormSuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs[this.LIST_REF], DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <PageView
    class="LegalDocumentsListView"
    data-test-id="legal_documents_list-view"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Legal documents
      </h1>
      <ui-button
        data-test-id="create-button"
        @clickbutton="isModalOpened = true"
      >
        Create legal document
      </ui-button>
    </div>

    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        :ref="LIST_REF"
        :facets="facets"
        :filters="`${getOperatorFilter({ attribute: 'cs_operator_id' })} OR cs_operator_id:null`"
        :index="LEGAL_DOCUMENTS_INDEX"
        :table-config="mainConfig"
        :item-actions="itemActions"
        data-test-id="list"
      />
    </div>

    <LegalDocumentsForm
      v-if="isModalOpened"
      :legal-document-uuid="legalDocumentUuid"
      :legal-document-to-copy-uuid="legalDocumentUuidToCopy"
      :on-success="onFormSuccess"
      @closeModal="closeModal"
    />

    <LegalDocumentPublishModal
      v-if="isPublishModalOpen"
      :legal-document="legalDocument"
      :on-success="onFormSuccess"
      @closeModal="closeModal"
    />

    <LegalDocumentDeleteModal
      v-if="isDeleteModalOpen"
      :legal-document="legalDocument"
      :on-success="onFormSuccess"
      @closeModal="closeModal"
    />
  </PageView>
</template>

