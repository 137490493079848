<script>
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import pull from 'lodash/pull';
import toNumber from 'lodash/toNumber';
import values from 'lodash/values';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { MuiInputFile } from '@emobg/motion-ui';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiModal,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { BUTTON_TYPES } from '@emobg/vue-base';
import { getBase64 } from '@emobg/web-utils';
import { PAYOUT_TYPE } from '@emobg/web-api-client';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { CancelButton, LabelWithTooltip, SaveButton } from '@/components';

export default {
  name: 'PayoutFormComponent',
  components: {
    CancelButton,
    LabelWithTooltip,
    MuiAlgoliaSelect,
    MuiInputFile,
    MuiInputText,
    MuiSelect,
    MuiModal,
    MuiValidationWrapper,
    MuiTextarea,
    SaveButton,
  },
  directives: {
    Validate,
  },
  props: {
    uuid: {
      required: true,
      type: String,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isFormValid: false,
      file: null,
      inputs: {
        type: null,
        reason: '',
        attachment: null,
        case_event_id: null,
        booking_uuid: null,
        amount: '',
      },
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperator: userState => userState.operators.active,
    }),
    ...mapState(DOMAINS_MODEL.crm.payouts, {
      payoutStatus: state => state.newPayout.STATUS,
      payoutData: state => state.newPayout.data,
      payoutError: state => state.newPayout.error,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    payoutTypes() {
      let types = values(PAYOUT_TYPE);

      if (!isEmpty(this.activeOperator) && this.activeOperator.name.toLowerCase() !== 'brunel') {
        types = pull(types, PAYOUT_TYPE.driverReward);
      }

      return types;
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.modalConfig = {
      title: this.$t('CRM.Payouts.create.title'),
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.payouts, [
      'postUserPayout',
      'postCompanyPayout',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    getFile(file) {
      return getBase64(file, {
        replaceHeader: true,
      }).then(response => response).catch(({ message }) => {
        this.$throwError({ message });
        return undefined;
      });
    },
    async savePayout() {
      if (this.file) {
        this.inputs.attachment = await this.getFile(this.file);
      }
      if (this.isFormValid) {
        const payload = {
          uuid: this.uuid,
          request: {
            ...this.inputs,
            operator_uuid: this.activeOperator.uuid,
          },
        };

        if (this.isCompany) {
          await this.postCompanyPayout(payload);
        } else {
          await this.postUserPayout(payload);
        }

        if (this.payoutStatus.ERROR) {
          const message = get(this.payoutError, 'message');
          const exceptionMessage = get(this.payoutError, 'exception_message');
          this.$throwError({ message: message || exceptionMessage });
        } else {
          this.notify({ message: this.$t('CRM.Payouts.create.successMessage') });
          this.closeModal();
        }
      }
    },
    onChangeFile($e, files) {
      this.file = head(files);
    },
    incorrectFormatValidator() {
      return {
        isValid: !!this.inputs.amount.match(/^\d+([,.]?\d{1,2})?$/),
        message: this.$t('CRM.Payouts.create.ibanFormatError'),
      };
    },
    maximumAmountValidator(value) {
      return {
        isValid: toNumber(value.replace(',', '.')) <= 500,
        message: this.$t('CRM.Payouts.create.ibanAmountError'),
      };
    },
    closeModal() {
      this.inputs = {
        type: null,
        reason: '',
        attachment: null,
        case_event_id: null,
        booking_uuid: null,
        amount: '',
      };

      this.$emit('input', false);
    },
  },
};
</script>

<template>
  <MuiModal
    v-bind="modalConfig"
    :is-open="value"
    @modal-closed="closeModal"
  >
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="py-3">
          <MuiSelect
            v-model="inputs.type"
            v-validate="{
              isRequired: true,
            }"
            :options="payoutTypes"
            :label="`${$t('CRM.Payouts.create.typeLabel')}*`"
            :placeholder="$t('CRM.Payouts.create.typePlaceholder')"
            name="type"
            class="w-100 mb-2"
          />
          <MuiTextarea
            v-model="inputs.reason"
            v-validate="{
              isRequired: true,
            }"
            :label="`${$t('CRM.Payouts.create.reasonLabel')}*`"
            :placeholder="$t('CRM.Payouts.create.reasonPlaceholder')"
            class="w-100 mb-2"
            name="reason"
          />
          <LabelWithTooltip
            :label="`${$t('CRM.Payouts.create.relatedBookingsLabel')}*`"
            :tooltip="$t('CRM.Payouts.create.relatedBookingsTooltip')"
          />
          <MuiAlgoliaSelect
            v-model="inputs.booking_uuid"
            v-validate="{
              isRequired: true,
            }"
            :filters="`user_uuid:${uuid}`"
            :title="({ id, vehicle_brand, vehicle_model }) =>
              `${id} - ${vehicle_brand} ${vehicle_model}`"
            :placeholder="$t('CRM.Payouts.create.relatedBookingsPlaceholder')"
            :index="ALGOLIA_INDEXES.csBookings"
            class="w-100 mb-2"
            name="booking"
            path-value="uuid"
          />
          <LabelWithTooltip
            :label="$t('CRM.Payouts.create.attachmentLabel')"
            :tooltip="$t('CRM.Payouts.create.attachmentTooltip')"
          />
          <MuiInputFile
            :max-file-size="10000000"
            :on-change="onChangeFile"
            :button-text="$t('CRM.Payouts.create.attachmentButton')"
            full-width
            name="attachment"
          />
          <LabelWithTooltip
            :label="$t('CRM.Payouts.create.relatedCaseLabel')"
            :tooltip="$t('CRM.Payouts.create.relatedCaseTooltip')"
          />
          <MuiAlgoliaSelect
            v-model="inputs.case_event_id"
            :filters="getOperatorFilter({ attribute: 'cs_operator_id' })"
            :title="(r) => `${r.id} - ${r.case_type_name_translations.en_GB}`"
            :placeholder="$t('CRM.Payouts.create.relatedCasePlaceholder')"
            :index="ALGOLIA_INDEXES.caseEvents"
            class="w-100 mb-2"
            name="case_event_id"
            path-value="id"
          />
          <MuiInputText
            v-model="inputs.amount"
            v-validate="{
              incorrectFormatValidator,
              maximumAmountValidator,
              isRequired: true,
            }"
            :label="`${$t('CRM.Payouts.create.amountLabel')}*`"
            class="w-100"
            name="amount"
          />
          <span class="font-s">{{ $t('CRM.Payouts.create.amountTooltip') }}</span>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end p-3">
        <CancelButton @click="closeModal" />
        <SaveButton
          :loading="payoutStatus.LOADING"
          :disabled="!isFormValid"
          class="mr-2"
          @click="savePayout"
        />
      </div>
    </template>
  </MuiModal>
</template>
