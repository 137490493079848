import { render, staticRenderFns } from "./AgentsView.vue?vue&type=template&id=2b1305fa&"
import script from "./AgentsView.vue?vue&type=script&lang=js&"
export * from "./AgentsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b1305fa')) {
      api.createRecord('2b1305fa', component.options)
    } else {
      api.reload('2b1305fa', component.options)
    }
    module.hot.accept("./AgentsView.vue?vue&type=template&id=2b1305fa&", function () {
      api.rerender('2b1305fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/CRM/Agents/AgentsView.vue"
export default component.exports