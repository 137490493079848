var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        attrs: { "is-open": _vm.value },
        on: { "modal-closed": _vm.closeModal },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
                  [
                    _c(
                      "div",
                      { staticClass: "py-3" },
                      [
                        _c("MuiSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n            isRequired: true,\n          }",
                            },
                          ],
                          staticClass: "w-100 mb-2",
                          attrs: {
                            options: _vm.payoutTypes,
                            label: `${_vm.$t("CRM.Payouts.create.typeLabel")}*`,
                            placeholder: _vm.$t(
                              "CRM.Payouts.create.typePlaceholder"
                            ),
                            name: "type",
                          },
                          model: {
                            value: _vm.inputs.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "type", $$v)
                            },
                            expression: "inputs.type",
                          },
                        }),
                        _c("MuiTextarea", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n            isRequired: true,\n          }",
                            },
                          ],
                          staticClass: "w-100 mb-2",
                          attrs: {
                            label: `${_vm.$t(
                              "CRM.Payouts.create.reasonLabel"
                            )}*`,
                            placeholder: _vm.$t(
                              "CRM.Payouts.create.reasonPlaceholder"
                            ),
                            name: "reason",
                          },
                          model: {
                            value: _vm.inputs.reason,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "reason", $$v)
                            },
                            expression: "inputs.reason",
                          },
                        }),
                        _c("LabelWithTooltip", {
                          attrs: {
                            label: `${_vm.$t(
                              "CRM.Payouts.create.relatedBookingsLabel"
                            )}*`,
                            tooltip: _vm.$t(
                              "CRM.Payouts.create.relatedBookingsTooltip"
                            ),
                          },
                        }),
                        _c("MuiAlgoliaSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n            isRequired: true,\n          }",
                            },
                          ],
                          staticClass: "w-100 mb-2",
                          attrs: {
                            filters: `user_uuid:${_vm.uuid}`,
                            title: ({ id, vehicle_brand, vehicle_model }) =>
                              `${id} - ${vehicle_brand} ${vehicle_model}`,
                            placeholder: _vm.$t(
                              "CRM.Payouts.create.relatedBookingsPlaceholder"
                            ),
                            index: _vm.ALGOLIA_INDEXES.csBookings,
                            name: "booking",
                            "path-value": "uuid",
                          },
                          model: {
                            value: _vm.inputs.booking_uuid,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "booking_uuid", $$v)
                            },
                            expression: "inputs.booking_uuid",
                          },
                        }),
                        _c("LabelWithTooltip", {
                          attrs: {
                            label: _vm.$t("CRM.Payouts.create.attachmentLabel"),
                            tooltip: _vm.$t(
                              "CRM.Payouts.create.attachmentTooltip"
                            ),
                          },
                        }),
                        _c("MuiInputFile", {
                          attrs: {
                            "max-file-size": 10000000,
                            "on-change": _vm.onChangeFile,
                            "button-text": _vm.$t(
                              "CRM.Payouts.create.attachmentButton"
                            ),
                            "full-width": "",
                            name: "attachment",
                          },
                        }),
                        _c("LabelWithTooltip", {
                          attrs: {
                            label: _vm.$t(
                              "CRM.Payouts.create.relatedCaseLabel"
                            ),
                            tooltip: _vm.$t(
                              "CRM.Payouts.create.relatedCaseTooltip"
                            ),
                          },
                        }),
                        _c("MuiAlgoliaSelect", {
                          staticClass: "w-100 mb-2",
                          attrs: {
                            filters: _vm.getOperatorFilter({
                              attribute: "cs_operator_id",
                            }),
                            title: (r) =>
                              `${r.id} - ${r.case_type_name_translations.en_GB}`,
                            placeholder: _vm.$t(
                              "CRM.Payouts.create.relatedCasePlaceholder"
                            ),
                            index: _vm.ALGOLIA_INDEXES.caseEvents,
                            name: "case_event_id",
                            "path-value": "id",
                          },
                          model: {
                            value: _vm.inputs.case_event_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "case_event_id", $$v)
                            },
                            expression: "inputs.case_event_id",
                          },
                        }),
                        _c("MuiInputText", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                incorrectFormatValidator:
                                  _vm.incorrectFormatValidator,
                                maximumAmountValidator:
                                  _vm.maximumAmountValidator,
                                isRequired: true,
                              },
                              expression:
                                "{\n            incorrectFormatValidator,\n            maximumAmountValidator,\n            isRequired: true,\n          }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            label: `${_vm.$t(
                              "CRM.Payouts.create.amountLabel"
                            )}*`,
                            name: "amount",
                          },
                          model: {
                            value: _vm.inputs.amount,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "amount", $$v)
                            },
                            expression: "inputs.amount",
                          },
                        }),
                        _c("span", { staticClass: "font-s" }, [
                          _vm._v(
                            _vm._s(_vm.$t("CRM.Payouts.create.amountTooltip"))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end p-3" },
                  [
                    _c("CancelButton", { on: { click: _vm.closeModal } }),
                    _c("SaveButton", {
                      staticClass: "mr-2",
                      attrs: {
                        loading: _vm.payoutStatus.LOADING,
                        disabled: !_vm.isFormValid,
                      },
                      on: { click: _vm.savePayout },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "MuiModal",
      _vm.modalConfig,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }